import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import he from 'he';

const PostDetail = ({ posts, }) => {
    const { slug } = useParams(); // Ottieni lo slug dai parametri della URL
    const post = posts.find(post => post.slug === slug); // Trova il post corrispondente usando lo slug
  

  if (!post) {
    return <div>Post non trovato</div>;
  }

  const decodedTitle = he.decode(post.title);

  return (

      <article id='post-detail' className="bg-gray-800 rounded-lg space-y-8 max-w-3xl mx-auto dark:text-white p-8 flex-1 sm:p-6 sm:p-8 lg:p-10">
        <Helmet>
          <title>{decodedTitle} | Alberto Gaia</title>
          <meta name="description" content={decodedTitle} />
        </Helmet>
        <h1 className="text-xl font-bold mb-4"> {decodedTitle}</h1>
        <p className="text-gray-500 mb-4">Scritto da {post.author}, {post.date}</p>
        {post.image ? <img src={post.image} alt={post.title} className="w-full h-auto mb-6 rounded-xl" /> : ''}
        <div dangerouslySetInnerHTML={{ __html: post.content }}  className=''/>
      </article>

  );
};

export default PostDetail;
