import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import PhotoAlbum from 'react-photo-album';

const ProjectDetail = () => {
  const { slug } = useParams();
  const [project, setProject] = useState(null);
  const [index, setIndex] = useState(-1);

  useEffect(() => {
    fetch('/assets/projects.json')
      .then(response => response.json())
      .then(data => {
        const foundProject = data.projects.find(p => p.slug === slug);
        setProject(foundProject);
      })
      .catch(error => console.error('Error fetching project content:', error));
  }, [slug]);

  const renderContent = (content) => {
    return content.map((item, index) => {
      switch (item.type) {
        case 'heading':
          return React.createElement(`h${item.level}`, { key: index, className: `text-${item.level === 1 ? '3xl' : '2xl'} font-bold mb-4 text-left uppercase inline-block border-b-4 border-teal-400 font-mono dark:text-white` }, item.text);
        case 'paragraph':
          return <p key={index} className="text-md text-left mb-4">{item.text}</p>;
        case 'link':
          return (
            <div key={index} className="text-center mt-4">
              <Link to={item.url} target="_blank" rel="noopener noreferrer">
                <button className="bg-teal-400 hover:bg-teal-500 text-white font-bold py-2 px-4 rounded">
                  {item.text}
                </button>
              </Link>
            </div>
          );
        case 'list':
          return (
            <ul key={index} className="list-disc list-inside mb-4">
              {item.items.map((listItem, idx) => (
                <li key={idx} className="text-md text-left mb-2">{listItem}</li>
              ))}
            </ul>
          );
        default:
          return null;
      }
    });
  };

  if (!project) {
    return <div>Loading...</div>;
  }

  const photos = project.images.map((src) => ({ src, width: 1080, height: 780 }));

  const renderPhoto = ({ photo, imageProps }) => (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <img
        {...imageProps}
        alt={photo.alt}
        style={{ width: '100%', height: '100%', objectFit: 'cover', padding: '5px', borderRadius: '10px', cursor: 'pointer' }}
        className='link'
      />
    </div>
  );

  return (
    <div className="space-y-8 max-w-3xl mx-auto dark:text-white p-8 flex-1 sm:p-6 sm:p-8 lg:p-10 overflow-y-scroll is_overflow bg-black bg-opacity-80">
      <Helmet>
        <title>{project.title} - Dettagli del Progetto</title>
        <meta name="description" content={project.description} />
      </Helmet>
      <div className="thumbnail rounded-lg mx-auto">
        <img src={project.thumbnail} alt={project.title} className="object-contain h-[300px] rounded-xl mx-auto" />
      </div>
      {renderContent(project.content)}
      <div className="image-gallery mt-8">
        <h2 className="text-2xl font-bold mb-4 text-left uppercase inline-block border-b-4 border-teal-400 font-mono dark:text-white">Galleria</h2>
        <PhotoAlbum photos={photos} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} renderPhoto={renderPhoto} />
        <Lightbox
          slides={photos}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
        />
      </div>
    </div>
  );
};

export default ProjectDetail;
