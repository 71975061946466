import React from 'react';
import ContactMe from '../Components/ContactMe';
import { Helmet } from 'react-helmet-async';

const ChatbotDevelopment = () => {
  return (
    <div className="p-6 text-white" id='chatBotDev'>
      <Helmet>
        <title>Chatbot Development | Alberto Gaia</title>
        <meta name="description" content="Scopri come posso sviluppare chatbot avanzati per migliorare l'interazione con i clienti e automatizzare le risposte." />
      </Helmet>
      <div className=" bg-opacity-50 text-white mb-8">
        <h1 className="text-3xl font-bold mb-6 text-left uppercase inline-block border-b-4 border-teal-400 font-mono text-white">&#60; Chatbot Development /&#62;</h1>
        <div className="">
          <p className="text-lg text-left">
            I Chatbot sono programmi informatici progettati per simulare conversazioni umane attraverso l'intelligenza artificiale. Sono utilizzati in una vasta gamma di applicazioni, dalle risposte automatiche ai clienti sui siti web, all'assistenza nelle app di messaggistica.
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
        <div className="bg-teal-900 p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-2">A cosa servono?</h2>
          <p className='text-lg'>
            I Chatbot aiutano a migliorare l'interazione con i clienti, fornendo risposte immediate e automatizzate alle domande frequenti. Possono anche raccogliere dati preziosi e migliorare l'efficienza operativa riducendo il carico di lavoro del personale umano.
          </p>
        </div>
        <div className="bg-teal-900 p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-2">Perché scegliere me?</h2>
          <p className='text-lg'>
            Offro soluzioni su misura che utilizzano le più recenti tecnologie di intelligenza artificiale per garantire interazioni fluide e naturali. Mi dedico a creare chatbot che non solo rispondono alle esigenze attuali, ma sono anche scalabili per il futuro.
          </p>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Cosa posso fare per i miei clienti?</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="bg-gray-700 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-black mb-2">Integrazione su Siti Web</h3>
            <p className='text-lg'>Implementazione di chatbot che possono rispondere alle domande dei clienti direttamente sul tuo sito web.</p>
          </div>
          <div className="bg-gray-700 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-black mb-2">Automazione delle Risposte</h3>
            <p className='text-lg'>Creazione di risposte automatizzate per le domande frequenti, migliorando l'efficienza del servizio clienti.</p>
          </div>
          <div className="bg-gray-700 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-black mb-2">Supporto Multilingue</h3>
            <p className='text-lg'>Sviluppo di chatbot che possono comunicare con i clienti in diverse lingue.</p>
          </div>
          <div className="bg-gray-700 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-black mb-2">Integrazione con App di Messaggistica</h3>
            <p className='text-lg'>Implementazione di chatbot su piattaforme di messaggistica come Facebook Messenger, WhatsApp e altre.</p>
          </div>
          <div className="bg-gray-700 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-black mb-2">Analisi e Reportistica</h3>
            <p className='text-lg'>Fornitura di strumenti di analisi per monitorare le performance del chatbot e migliorare continuamente l'interazione.</p>
          </div>
          <div className="bg-gray-700 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-black mb-2">Personalizzazione</h3>
            <p className='text-lg'>Creazione di chatbot personalizzati per rispondere alle specifiche esigenze del tuo business.</p>
          </div>
        </div>
      </div>

      <ContactMe />
    </div>
  );
};

export default ChatbotDevelopment;
