import React from 'react';
import ContactMe from '../Components/ContactMe';
import { Helmet } from 'react-helmet-async';

const BackendDevelopment = () => {
  return (
    <div className="p-6 text-white" id='backEndDev'>
      <Helmet>
        <title>Backend Development | Alberto Gaia</title>
        <meta name="description" content="Il Backend Development riguarda la creazione e la gestione del server, del database e della logica di applicazione che si trova dietro le quinte di un sito web o di un'applicazione" />
      </Helmet>
      <div className=" bg-opacity-50 text-white mb-8">
      <h1 className="text-3xl font-bold mb-6 text-left uppercase inline-block border-b-4 border-teal-400 font-mono text-white">&#60; Backend Development /&#62;</h1>
        <div className="">
          <p className="text-lg text-left">
            Il Backend Development riguarda la creazione e la gestione del server, del database e della logica di applicazione che si trova dietro le quinte di un sito web o di un'applicazione. È la parte che non è visibile agli utenti ma che rende possibile il funzionamento del frontend.
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
        <div className="bg-teal-900 p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-2">A cosa serve?</h2>
          <p className='text-lg'>
            Il Backend Development è cruciale per gestire i dati, la sicurezza e le performance di un'applicazione. Un backend ben progettato garantisce che tutte le richieste dell'utente siano elaborate correttamente e in modo sicuro.
          </p>
        </div>
        <div className="bg-teal-900 p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-2">Perché scegliere me?</h2>
          <p className='text-lg'>
            Mi impegno a fornire soluzioni robuste e scalabili che rispondano alle esigenze dei miei clienti. Utilizzo le tecnologie più moderne e seguo le migliori pratiche per assicurare un'infrastruttura solida e performante.
          </p>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Cosa posso fare per i miei clienti?</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="bg-gray-700 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-black mb-2">Gestione Database</h3>
            <p className='text-lg'>Progettazione e gestione di database relazionali e non relazionali per archiviare i dati in modo sicuro e scalabile.</p>
          </div>
          <div className="bg-gray-700 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-black mb-2">Autenticazione e Autorizzazione</h3>
            <p className='text-lg'>Implementazione di sistemi di autenticazione e autorizzazione per proteggere i dati degli utenti.</p>
          </div>
          <div className="bg-gray-700 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-black mb-2">Integrazione Servizi</h3>
            <p className='text-lg'>Integrazione di servizi di terze parti e microservizi per estendere le funzionalità dell'applicazione.</p>
          </div>
          <div className="bg-gray-700 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-black mb-2">Ottimizzazione Performance</h3>
            <p className='text-lg'>Ottimizzazione delle performance del server per garantire tempi di risposta rapidi e affidabili.</p>
          </div>
          <div className="bg-gray-700 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-black mb-2">Monitoraggio e Manutenzione</h3>
            <p className='text-lg'>Monitoraggio continuo e manutenzione dell'infrastruttura per assicurare un funzionamento senza interruzioni.</p>
          </div>
        </div>
      </div>

      <ContactMe />
    </div>
  );
};

export default BackendDevelopment;
