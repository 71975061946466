import React from 'react';
import { Accordion } from 'rsuite';

import 'rsuite/dist/rsuite-no-reset.min.css';

import '../CookiePolicy.css'

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy text-sm">
      <h1>Privacy Policy di GAIA ALBERTO</h1>
      <p>
        GAIA ALBERTO raccoglie alcuni Dati Personali dei propri Utenti.
      </p>

      <Accordion bordered>
        <Accordion.Panel header="Gestione dei tag" defaultExpanded>
          <h3>Google Tag Manager (Google Ireland Limited)</h3>
          <p>
            Dati Personali: Strumenti di Tracciamento<br />
            Luogo del trattamento: Irlanda – <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          </p>
        </Accordion.Panel>

        <Accordion.Panel header="Statistica">
          <h3>Google Analytics (Universal Analytics) con IP anonimizzato (Google Ireland Limited)</h3>
          <p>
            Dati Personali: Dati di utilizzo; Strumenti di Tracciamento<br />
            Luogo del trattamento: Irlanda – <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> – <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">Opt Out</a>
          </p>
          <p>
            Durata di archiviazione:<br />
            AMP_TOKEN: 1 ora<br />
            _ga: 2 anni<br />
            _gac*: 3 mesi<br />
            _gat: 1 minuto<br />
            _gid: 1 giorno
          </p>
        </Accordion.Panel>

        <Accordion.Panel header="Chatbot">
          <h3>Chatbot</h3>
          <p>
            Il chatbot raccoglie il nome e l'email degli utenti per tracciare le conversazioni e migliorare il servizio di risposte automatiche.<br />
            Dati Personali: Nome; Email<br />
            Finalità del trattamento: Tracciamento delle conversazioni, miglioramento del servizio di chatbot<br />
            Luogo del trattamento: Italia<br />
          </p>
        </Accordion.Panel>

        <Accordion.Panel header="Informazioni su come disattivare gli annunci pubblicitari basati sugli interessi">
          <p>
            Oltre a qualsiasi funzione di opt-out fornita da uno qualsiasi dei servizi elencati in questo documento, gli Utenti possono leggere di più su come disattivare gli annunci pubblicitari basati sugli interessi nell'apposita sezione della Cookie Policy.
          </p>
        </Accordion.Panel>

        <div className="p-3">
          <h2>Informazioni di contatto</h2>
          <p>
            <strong>Titolare del Trattamento dei Dati:</strong><br />
            GAIA ALBERTO<br />
            Email: <a href="mailto:albertogaia.dev@gmail.com">albertogaia.dev@gmail.com</a>
          </p>

          <p>
            <strong>Ultima modifica:</strong> 28 Giugno 2024
          </p>
        </div>
      </Accordion>
    </div>
  );
};

export default PrivacyPolicy;
