import React from 'react';
import ContactMe from '../Components/ContactMe';
import { Helmet } from 'react-helmet-async';

const FrontendDevelopment = () => {
  return (
    <div className="p-6 text-white " id='frontEndDev'>
        <Helmet>
            <title>Frontend Development | Alberto Gaia</title>
            <meta name="description" content="Scopri come posso creare interfacce utente intuitive e accattivanti per il tuo sito web o applicazione." />
        </Helmet>
        
      <div className=" bg-opacity-50 text-white mb-8">
            <h1 className="text-3xl font-bold mb-6 text-left uppercase inline-block border-b-4 border-teal-400 font-mono text-white">&#60; Frontend Development /&#62;</h1>
            <div className="">
                <p className="text-lg text-left">
                Il Frontend Development riguarda la creazione e l'implementazione dell'interfaccia utente di un sito web o un'applicazione. È la parte visibile agli utenti e include tutto ciò che vedono e con cui interagiscono, come layout, design, pulsanti, immagini, testi e animazioni.
                </p>
            </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
            <div className="bg-teal-900 p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-2">A cosa serve?</h2>
            <p className='text-lg'>
                Il Frontend Development è cruciale per creare esperienze utente coinvolgenti e intuitive. Un frontend ben progettato non solo migliora l'estetica di un sito web, ma ottimizza anche la navigazione, aumentando la soddisfazione dell'utente e il tasso di conversione.
            </p>
            </div>
            <div className="bg-teal-900 p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-2">Perché scegliere me?</h2>
            <p className='text-lg'>
                Con anni di esperienza nel settore del web development, mi dedico a fornire soluzioni personalizzate che soddisfino le esigenze uniche di ogni cliente. Utilizzo le ultime tecnologie e seguo le migliori pratiche per garantire che ogni progetto sia un successo.
            </p>
            </div>
        </div>

        <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">Cosa posso fare per i miei clienti?</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="bg-gray-700 p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-black  mb-2">Layout Personalizzati</h3>
                <p className='text-lg'>Creazione di layout personalizzati e responsive che si adattano a qualsiasi dispositivo.</p>
            </div>
            <div className="bg-gray-700 p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-black  mb-2">Design Moderni</h3>
                <p className='text-lg'>Implementazione di design moderni e accattivanti utilizzando le ultime tecnologie web.</p>
            </div>
            <div className="bg-gray-700 p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-black  mb-2">Ottimizzazione Prestazioni</h3>
                <p className='text-lg'>Ottimizzazione delle prestazioni per garantire tempi di caricamento rapidi.</p>
            </div>
            <div className="bg-gray-700 p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-black  mb-2">Integrazione Backend</h3>
                <p className='text-lg'>Integrazione con backend e API per una funzionalità completa.</p>
            </div>
            <div className="bg-gray-700 p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-black  mb-2">Test e Debug</h3>
                <p className='text-lg'>Test e debug per assicurare un'esperienza utente senza problemi.</p>
            </div>
            </div>
        </div>

        <ContactMe />



    </div>
  );
};

export default FrontendDevelopment;
