import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import routePaths from '../routePaths';

const Header = ({ categories }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isActive = (path) => location.pathname === path;

  return (
    <>
      <nav className="bg-black" ref={menuRef}>
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-16 items-center justify-between">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <button
                type="button"
                className="relative inline-flex items-center justify-center p-2 text-gray-400 hover:bg-gray-700 hover:text-teal-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded={isOpen}
                onClick={toggleMenu}
              >
                <span className="sr-only">Open main menu</span>
                {isOpen ? (
                  <FontAwesomeIcon icon={faTimes} className="block h-6 w-6" />
                ) : (
                  <FontAwesomeIcon icon={faBars} className="block h-6 w-6" />
                )}
              </button>
            </div>
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex flex-shrink-0 items-center">
                <Link to="/" onClick={() => setIsOpen(false)}>
                  <h2 className='text-3xl font-black text-white hover:text-teal-400'>
                    <span className='text-teal-400'>
                      A
                    </span>
                    G
                  </h2>
                </Link>
              </div>
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex space-x-4">
                  <Link
                    to={routePaths.home}
                    className={`px-3 py-2 text-sm font-bold ${isActive(routePaths.home) ? 'text-teal-400 font-black' : 'text-gray-300  hover:text-teal-400   transition hover:text-teal-400'}`}
                    aria-current={isActive(routePaths.home) ? 'page' : undefined}
                    onClick={() => setIsOpen(false)}
                  >
                    Home
                  </Link>
                  <Link
                    to={`/posts`}
                    className={`px-3 py-2 text-sm font-bold ${isActive(`/posts`) ? 'text-teal-400 font-black' : 'text-gray-300  hover:text-teal-400  transition hover:text-teal-400'}`}
                    onClick={() => setIsOpen(false)}
                  >
                    Blog
                  </Link>
                  <Link
                    to={routePaths.services}
                    className={`px-3 py-2 text-sm font-bold ${isActive(routePaths.services) ? 'text-teal-400 font-black' : 'text-gray-300  hover:text-teal-400  transition hover:text-teal-400'}`}
                    onClick={() => setIsOpen(false)}
                  >
                    Servizi
                  </Link>
                  <Link
                    to={routePaths.portfolio}
                      className={`px-3 py-2 text-sm font-bold ${isActive(routePaths.prj_summaraize) ? 'text-teal-400 font-black' : 'text-gray-300  hover:text-teal-400  transition hover:text-teal-400'}`}
                      onClick={() => setIsOpen(false)}
                    >
                    Portfolio
                  </Link>
                  <Link
                    to={routePaths.contact}
                    className={`px-3 py-2 text-sm font-bold ${isActive(routePaths.contact) ? 'text-teal-400 font-black' : 'text-gray-300  hover:text-teal-400  transition hover:text-teal-400'}`}
                    onClick={() => setIsOpen(false)}
                  >
                    Contattami
                  </Link>
                </div>
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <a href="https://www.linkedin.com/in/alberto-gaia/" target="_blank" rel="noopener noreferrer" className="text-blue-500 mx-2">
                <FontAwesomeIcon icon={faLinkedin} className="text-white hover:text-teal-400 text-2xl" />
              </a>
            </div>
          </div>
        </div>

        <div className={`${isOpen ? 'block' : 'hidden'} sm:hidden`} id="mobile-menu" >
          <div className="space-y-1 px-2 pb-3 pt-2">
            <Link
              to={routePaths.home}
              className={`block px-3 py-2 text-base font-bold ${isActive(routePaths.home) ? 'text-teal-400 font-black' : 'text-gray-300  hover:text-teal-400  transition hover:text-teal-400'}`}
              aria-current={isActive(routePaths.home) ? 'page' : undefined}
              onClick={() => setIsOpen(false)}
            >
              Home
            </Link>
            <Link
              to={`/posts`}
              className={`block px-3 py-2 text-sm font-bold ${isActive(`/posts`) ? 'text-teal-400 font-black' : 'text-gray-300  hover:text-teal-400  transition hover:text-teal-400'}`}
              onClick={() => setIsOpen(false)}
            >
              Blog
            </Link>
            <Link
              to={routePaths.portfolio}
                className={`block px-3 py-2 text-sm font-bold ${isActive(routePaths.prj_summaraize) ? 'text-teal-400 font-black' : 'text-gray-300  hover:text-teal-400  transition hover:text-teal-400'}`}
                onClick={() => setIsOpen(false)}
              >
              Portfolio
            </Link>
            <Link
              to={routePaths.services}
              className={`block px-3 py-2 text-base font-bold ${isActive(routePaths.services) ? 'text-teal-400 font-black' : 'text-gray-300  hover:text-teal-400  transition hover:text-teal-400'}`}
              onClick={() => setIsOpen(false)}
            >
              Servizi
            </Link>
            <Link
              to={routePaths.contact}
              className={`block px-3 py-2 text-base font-bold ${isActive(routePaths.contact) ? 'text-teal-400 font-black' : 'text-gray-300  hover:text-teal-400  transition hover:text-teal-400'}`}
              onClick={() => setIsOpen(false)}
            >
              Contattami
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
