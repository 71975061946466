import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhoneAlt, faEnvelope, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn, faJsSquare, faReact, faVuejs, faPhp, faWordpressSimple } from '@fortawesome/free-brands-svg-icons';
import { Helmet } from 'react-helmet';
import routePaths from '../routePaths';
import { Link } from 'react-router-dom';


const ContactPage = () => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="space-y-8 max-w-3xl overflow-y-none mx-auto text-white h-dvh flex-1 p-4 sm:p-6 sm:p-8 lg:p-10">

      <Helmet>
        <title>Contattami | Alberto Gaia</title>
        <meta name="description" content="Chi sono e come contattarmi" />
      </Helmet>
      <div className="max-w-7xl mx-auto py-16 px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold mb-4">Rimaniamo in <span className="text-teal-400">Contatto</span></h2>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          <div className="space-y-4">
            <div className="flex items-center p-4 bg-gray-800 rounded-md">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="text-teal-400 text-2xl mr-4" />
              <span>Torino, TO, IT</span>
            </div>
            <div className="flex items-center p-4 bg-gray-800 rounded-md">
              <FontAwesomeIcon icon={faPhoneAlt} className="text-teal-400 text-2xl mr-4" />
              <a href="tel:+39 3394497478">+39 339 4497 478</a>
            </div>
            <div className="flex items-center p-4 bg-gray-800 rounded-md hover:bg-teal-700 hover:text-white shadow-teal-400 shadow-md">
              <FontAwesomeIcon icon={faEnvelope} className="text-teal-400 text-2xl mr-4" />
              <a href="mailto:hello@albertogaia.xyz" target="_blank" rel="noreferrer">hello@albertogaia.xyz</a>
            </div>
            <div className="flex items-center p-4 bg-gray-800 rounded-md hover:bg-teal-700 hover:text-white shadow-teal-400 shadow-md">
              <FontAwesomeIcon icon={faLinkedinIn} className="text-teal-400 text-2xl mr-4" />
              <a href="https://www.linkedin.com/in/alberto-gaia/" target="_blank" rel="noreferrer">@alberto-gaia</a>
            </div>
          </div>
          <div className="space-y-4  cursor-pointer" onClick={togglePopup}>
            <div className="flex items-center gap-3 p-4 bg-gray-800 rounded-md hover:bg-gray-700 hover:text-white justify-between hover:bg-teal-700 hover:text-white shadow-teal-400 shadow-md">
              <div className="image w-24">
                <img src="/images/1636989275052.jpeg" alt="" className="rounded-full" />
              </div>
              <div className="personal-info font-medium text-gray-300">
                <p>Nome: <span className="text-white font-bold">Alberto Gaia</span></p>
                <p>Età: <span className="text-white font-bold">29</span></p>
                <p>Luogo: <span className="text-white font-bold">Torino, IT</span></p>
              </div>
            </div>
            <div className="flex items-center p-4 bg-gray-800 rounded-md hover:bg-teal-700 hover:text-white shadow-teal-400 shadow-md">
              <FontAwesomeIcon icon={faCalendarAlt} className="text-teal-400 text-2xl mr-4" />
              <Link to={routePaths.booking}>Prenota un appuntamento</Link>
            </div>
          </div>
            {showPopup && (
              <div className="popup fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="p-6 bg-gray-800 rounded-md text-gray-300 w-100 shadow-teal-800 shadow-lg">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold text-white">Alberto Gaia</h2>
                    <button onClick={togglePopup} className="text-white">X</button>
                  </div>
                  <p>Mi chiamo <span className="text-white font-bold">Alberto Gaia</span>, ho 29 anni e vengo dalla vibrante città di Torino.</p>
                  <p>Sono un appassionato Web Developer con un forte interesse per l'Intelligenza Artificiale.</p>
                  <p>Mi piace esplorare nuove tecnologie e affrontare sfide stimolanti, con l'obiettivo di trasformare idee innovative in realtà digitali.</p>
                  <p><em>Never stop learning</em> è il mio mantra, perché credo fermamente che l'apprendimento continuo sia la chiave per il successo e l'innovazione.</p>
                  <div className="mt-4">
                    <h3 className="text-lg font-bold text-white mb-2">Tecnologie conosciute</h3>
                    <div className="flex flex-wrap gap-4">
                      <FontAwesomeIcon icon={faJsSquare} className="text-4xl text-yellow-500" title="JavaScript" />
                      <FontAwesomeIcon icon={faReact} className="text-4xl text-cyan-400" title="ReactJS" />
                      <FontAwesomeIcon icon={faVuejs} className="text-4xl text-green-500" title="VueJS" />
                      <FontAwesomeIcon icon={faPhp} className="text-4xl text-indigo-500" title="PHP" />
                      <FontAwesomeIcon icon={faWordpressSimple} className="text-4xl text-blue-600" title="WordPress" />
                      <img src="images/laravel-icon.png" alt="Laravel" title="Laravel" className="w-10 h-10" />
                      <img src="images/megento-icon.webp" alt="Magento" title="Magento" className="w-10 h-10" />
                      <img src="images/shopify.webp" alt="Shopify" title="Shopify" className="w-10 h-10" />
                      <img src="images/readypro-icon.png" alt="ReadyPro" title="ReadyPro" className="w-10 h-10 rounded-lg" />
                    </div>
                  </div>
                  <div className="mt-4">
                    <h3 className="text-lg font-bold text-white mb-2">Altre conoscenze</h3>
                    <div className="flex flex-wrap gap-4">
                      <img src="images/google-analytics-icon.webp" alt="Google Analytics" title="Google Analytics" className="w-10 h-10" />
                      <img src="images/google-ads-icon.png" alt="Google Ads" title="Google Ads" className="w-10 h-10" />
                      <img src="images/seo-icon.png" alt="SEO" title="SEO" className="w-10 h-10" />
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>

    </div>
  );
};

export default ContactPage;
