// src/pages/BookingPage.js
import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';

const BookingPage = () => {
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState('');
  const [type, setType] = useState('Call');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [full_name, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [appointments, setAppointments] = useState([]);

  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  const handleBooking = async () => {
    const newAppointment = {
      date: date.toISOString().split('T')[0], // Formatta la data in YYYY-MM-DD
      time,
      type,
      subject,
      message,
      full_name,
      email,
      phone,
    };

    try {
      await axios.post('https://albertogaia.xyz/ag-dashboard/public/api/appointments', newAppointment);
      setAppointments([...appointments, newAppointment]);
      alert('Appuntamento prenotato con successo!');
      setTime('');
      setType('Call');
      setSubject('');
      setMessage('');
      setFullName('');
      setEmail('');
      setPhone('');
    } catch (error) {
      console.error('Errore durante la prenotazione:', error);
      alert('C\'è stato un errore durante la prenotazione. Per favore riprova.');
    }
  };

  return (
    <div className="container mx-auto p-4 is_overflow">
      <h1 className="text-2xl font-bold mb-4">Prenota un Appuntamento</h1>
      <div className="calendar bg-teal-700 p-3 rounded-lg">
        <div className="grid gap-2 sm:flex sm:gap-2">
          <div className="mb-4 w-full sm:w-1/2">
            <Calendar
              onChange={handleDateChange}
              value={date}
              className="text-black rounded w-full"
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 w-full sm:w-1/2">
            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2" htmlFor="time">
                Scegli l'orario:
              </label>
              <input
                type="time"
                id="time"
                value={time}
                onChange={handleTimeChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2" htmlFor="type">
                Tipo di Appuntamento:
              </label>
              <select
                id="type"
                value={type}
                onChange={(e) => setType(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="Call">Call</option>
                <option value="VideoCall">VideoCall</option>
                <option value="Appuntamento in Sede">Appuntamento in Sede</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2" htmlFor="subject">
                Oggetto:
              </label>
              <input
                type="text"
                id="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2" htmlFor="message">
                Messaggio:
              </label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2" htmlFor="full_name">
                Nome Completo:
              </label>
              <input
                type="text"
                id="full_name"
                value={full_name}
                onChange={(e) => setFullName(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2" htmlFor="email">
                Email:
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2" htmlFor="phone">
                Numero di Telefono:
              </label>
              <input
                type="tel"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
          </div>
        </div>
        <div className="text-right mt-4">
          <button
            onClick={handleBooking}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Prenota
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookingPage;
