import React from 'react';
import { useParams } from 'react-router-dom';
import BlogPost from './BlogPost';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';

const BlogList = ({ posts, categories, forceTo }) => {
  const { slug } = useParams() ;
  const category = categories.find(category => category.slug === slug);
  const filteredPosts = category ? posts.filter(post => post.categories.includes(category.id)) : posts;

  if (!category) {
    return <div>Loading...</div>;
  }

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  };

  return (
    <section className="space-y-8 max-w-3xl overflow-hidden mx-auto dark:text-white p-8 max-h-screen flex-1 sm:p-6 sm:p-8 lg:p-10">
      <Helmet>
        <title>Blog | Alberto Gaia</title>
        <meta name="description" content="Leggi gli ultimi articoli del blog" />
      </Helmet>
      <h2 className="text-2xl font-bold text-white uppercase inline-block border-b-4 border-teal-400 font-mono ">
        {category.name}
      </h2>
      <Slider {...settings}>
        {filteredPosts.map(post => (
          <div key={post.id} className="p-4">
              <BlogPost
                title={post.title}
                author={post.author}
                date={post.date}
                image={post.image}
                excerpt={post.excerpt}
                slug={post.slug}
              />
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default BlogList;
