import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRect } from 'react-use-rect';
import routePaths from '../routePaths';
import { Helmet } from 'react-helmet';

const mock = ["Developer", "Freelancer", "AI Enthusiast"];

const SlideText = ({ source }) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [rect, setRect] = useState();
  const [rectRef] = useRect(setRect);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentItemIndex((index) =>
        index === source.length - 1 ? 0 : index + 1
      );
    }, 3000);
    return () => clearInterval(interval);
  }, [currentItemIndex, source]);

  return (
    <div
      style={{
        display: 'inline-flex',
        overflow: 'hidden',
        position: 'relative',
        transition: 'all 0.5s ease-in-out',
      }}
    >
      <span style={{ visibility: 'hidden' }}>{source[currentItemIndex]}</span>
      {source.map((text, index) => (
        <span
          ref={currentItemIndex === index ? rectRef : null}
          key={index}
          style={{
            position: 'absolute',
            top: (rect?.height ?? 0) * 2,
            transform: `translateY(${
              currentItemIndex === index ? `-${(rect?.height ?? 0) * 2}px` : 0
            })`,
            transition: 'all 1s ease-in-out',
          }}
        >
          {text}
        </span>
      ))}
    </div>
  );
};

const HomePage = ({ posts, categories }) => {
  return (
    <div className="space-y-8 max-w-3xl  mx-auto dark:text-white max-h-screen flex-1 p-4 sm:p-6 sm:p-8 lg:p-10 is_overflow">
      <Helmet>
        <title>Homepage | Alberto Gaia</title>
        <meta name="description" content="Alberto Gaia | Creative Web Developer, AI Enthusiast, Freelancer" />
      </Helmet>
      <div className="flex flex-col items-center justify-center text-center">
        <h2 className="uppercase text-4xl sm:text-5xl sm:text-6xl font-black">
          <span className="text-teal-400">Alberto</span> Gaia
        </h2>
        <h2 className="text-2xl sm:text-3xl sm:text-4xl font-black my-6 sm:my-10">
          <span className="text-gray-400">Creative</span> <SlideText source={mock} />
        </h2>
        <Link to={routePaths.contact} className="uppercase py-3 px-4 bg-teal-400 rounded-full font-bold pointer text-black inline-block mt-4 custom hover:bg-white hover:cursor-pointer hover:text-teal-800 transition ease-in-out custom">
          Get in Touch
        </Link>
      </div>
    </div>
  );
};

export default HomePage;
