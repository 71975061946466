import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const Portfolio = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetch('./assets/projects.json')
      .then(response => response.json())
      .then(data => setProjects(data.projects))
      .catch(error => console.error('Error fetching projects:', error));
  }, []);

  return (
    <div className="space-y-8 max-w-3xl mx-auto dark:text-white p-8 flex-1 sm:p-6 sm:p-8 lg:p-10 is_overflow">
      <Helmet>
        <title>Portfolio</title>
        <meta name="description" content="Scopri i progetti sviluppati, inclusi strumenti innovativi come SummarAIze e altro." />
      </Helmet>
      <div className="rounded-lg mb-8">
          <h1 className="text-3xl font-bold mb-6 text-left uppercase inline-block border-b-4 border-teal-400 font-mono dark:text-white">Portfolio</h1>
        <p className="text-md text-left">
          Qui trovi una raccolta dei miei progetti, sviluppati con passione e dedizione per esplorare le potenzialità delle tecnologie emergenti.
        </p>
      </div>
      <div className="grid gap-6">
        {projects.map((project, index) => (
          project.slug ? (
            <Link key={index} to={project.slug} className="block text-teal-400 hover:text-teal-300 text-lg font-semibold mb-2">
              <div className="rounded-lg p-4 bg-neutral-800 hover:bg-neutral-700 transition grid grid-cols-1 sm:grid-cols-3 gap-4 items-start shadow-md shadow-teal-400">
                <div className="sm:col-span-2">
                  <h2 className="text-xl font-bold">{project.title}</h2>
                  <p className="text-sm text-slate-400 font-medium">{project.description}</p>
                </div>
                <div className="flex flex-col sm:text-right text-xs space-y-2">
                  <div>
                    <span className="text-sm font-bold text-gray-300">Tags:</span>
                    <div className="mt-1">
                      {project.tags.map((tag, idx) => (
                        <span key={idx} className="inline-block bg-teal-600 text-white text-xs px-2 py-1 rounded ml-2 mt-2">{tag}</span>
                      ))}
                    </div>
                  </div>
                  <div>
                    <span className="text-sm font-bold text-gray-300">Technologies:</span>
                    <div className="mt-1">
                      {project.tecnologies.map((tech, idx) => (
                        <span key={idx} className="inline-block bg-gray-600 text-white text-xs px-2 py-1 rounded ml-2 mt-2">{tech}</span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ) : (
            <div key={index} className="block text-teal-400 text-lg font-semibold mb-2">
              <div className="rounded-lg p-4 bg-neutral-800 transition grid grid-cols-1 sm:grid-cols-3 gap-4 items-start">
                <div className="sm:col-span-2">
                  <h2 className="text-xl font-bold">{project.title}</h2>
                  <p className="text-sm text-slate-400 font-medium">{project.description}</p>
                </div>
                <div className="flex flex-col sm:text-right text-xs space-y-2">
                  <div>
                    <span className="text-sm font-bold text-gray-300">Tags:</span>
                    <div className="mt-1">
                      {project.tags.map((tag, idx) => (
                        <span key={idx} className="inline-block bg-teal-600 text-white text-xs px-2 py-1 rounded ml-2 mt-2">{tag}</span>
                      ))}
                    </div>
                  </div>
                  <div>
                    <span className="text-sm font-bold text-gray-300">Technologies:</span>
                    <div className="mt-1">
                      {project.tecnologies.map((tech, idx) => (
                        <span key={idx} className="inline-block bg-gray-600 text-white text-xs px-2 py-1 rounded ml-2 mt-2">{tech}</span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
