import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';


const servicesData = [
  {
    title: "Frontend Development",
    link: "/servizi/frontend-development",
  },
  {
    title: "Backend Development",
    link: "/servizi/backend-development",
  },
  {
    title: "Web App Customizzate",
    link: "/servizi/custom-web-apps",
  },
  {
    title: "Chatbot Development",
    link: "/servizi/chatbot-development",
  },
];

const Services = () => {
  return (
    <div className="space-y-8 max-w-3xl overflow-hidden mx-auto dark:text-white p-8 flex-1 sm:p-6 sm:p-8 lg:p-10">
      <Helmet>
        <title>Servizi | Alberto Gaia</title>
        <meta name="description" content="Offro una gamma completa di servizi per aiutarti a costruire e migliorare la tua presenza online. Frontend Development, Backend Development, Chatbot e Web App" />
      </Helmet>
      <div className="dark:text-white rounded-lg mb-8">
        <h1 className="text-3xl font-bold mb-6 text-left uppercase inline-block border-b-4 border-teal-400 font-mono dark:text-white">Servizi</h1>
        <p className="text-md text-left">
          Offro una gamma completa di servizi per aiutarti a costruire e migliorare la tua presenza online. Scopri di più su ciascun servizio cliccando sui link sottostanti.
        </p>
      </div>
      <div className="grid gap-6">
        {servicesData.map((service, index) => (
          <div key={index} className="rounded-lg p-4 bg-neutral-800 hover:bg-neutral-700 transition  shadow-md shadow-teal-400">
            <Link to={service.link} className="block text-teal-400 hover:text-teal-300 text-lg font-semibold">
              {service.title}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
