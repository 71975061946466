const routePaths = {
    home: "/",
    post: "/posts/:slug",
    posts: "/posts/",
    categories: "/category",
    category: "/category/:slug",
    contact: "/contattami",
    frontendDevelopment: "/servizi/frontend-development",
    backendDevelopment: "/servizi/backend-development",
    customWebApps: "/servizi/custom-web-apps",
    chatbotDevelopment: "/servizi/chatbot-development",
    services: "/servizi",
    project: "/portfolio/:slug",
    portfolio: "/portfolio",
    booking: "/booking"
  };
  
  export default routePaths;
  