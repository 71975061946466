import React from 'react';
import ContactMe from '../Components/ContactMe';
import { Helmet } from 'react-helmet-async';

const CustomWebApps = () => {
  return (
    <div className="p-6 text-white" id='webAppDev'>
      <Helmet>
        <title>Web App Customizzate | Alberto Gaia</title>
        <meta name="description" content="Scopri come possiamo sviluppare applicazioni web su misura per soddisfare le esigenze specifiche della tua azienda." />
      </Helmet>
      <div className="p-6  bg-opacity-50 text-white rounded-lg  mb-8">
        <h1 className="text-3xl font-bold mb-6 text-left inline-block border-b-4 border-teal-400 font-mono text-white">&#60; Web App Customizzate /&#62;</h1>
        <div className="">
          <p className="text-lg text-left">
            Le Web App Customizzate sono applicazioni web progettate su misura per soddisfare le esigenze specifiche di un'azienda o di un progetto. Offrono funzionalità personalizzate che non possono essere ottenute con soluzioni standard, garantendo un'esperienza utente ottimale e l'efficienza operativa.
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
        <div className="bg-teal-900 p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-2">A cosa servono?</h2>
          <p className='text-lg'>
            Le Web App Customizzate servono a fornire soluzioni specifiche e mirate per le esigenze uniche di un'azienda. Consentono di automatizzare i processi, migliorare la produttività e offrire funzionalità avanzate che migliorano l'interazione con i clienti e il personale interno.
          </p>
        </div>
        <div className="bg-teal-900 p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-2">Perché scegliere me?</h2>
          <p className='text-lg'>
            Mi dedico a creare soluzioni su misura che rispondano perfettamente alle esigenze dei miei clienti. Utilizzo tecnologie all'avanguardia e seguo le migliori pratiche di sviluppo per garantire applicazioni robuste, sicure e scalabili.
          </p>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Cosa posso fare per i miei clienti?</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="bg-gray-700 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-black mb-2">Analisi delle Esigenze</h3>
            <p className='text-lg'>Analisi dettagliata delle necessità dell'azienda per sviluppare una soluzione su misura.</p>
          </div>
          <div className="bg-gray-700 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-black mb-2">Progettazione UI/UX</h3>
            <p className='text-lg'>Creazione di interfacce utente intuitive e design che migliorano l'esperienza utente.</p>
          </div>
          <div className="bg-gray-700 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-black mb-2">Sviluppo Personalizzato</h3>
            <p className='text-lg'>Realizzazione di funzionalità specifiche e personalizzate per soddisfare le esigenze del cliente.</p>
          </div>
          <div className="bg-gray-700 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-black mb-2">Integrazione Sistemi</h3>
            <p className='text-lg'>Integrazione della web app con sistemi esistenti per un flusso di lavoro senza interruzioni.</p>
          </div>
          <div className="bg-gray-700 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-black mb-2">Manutenzione e Supporto</h3>
            <p className='text-lg'>Servizi di manutenzione continua e supporto tecnico per garantire il funzionamento ottimale.</p>
          </div>
          <div className="bg-gray-700 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-black mb-2">Ottimizzazione Prestazioni</h3>
            <p className='text-lg'>Ottimizzazione delle prestazioni dell'app per garantire tempi di risposta rapidi e un'esperienza utente fluida.</p>
          </div>
        </div>
      </div>

      <ContactMe />
    </div>
  );
};

export default CustomWebApps;
