import React from 'react';
import { Link } from 'react-router-dom';

const BlogPost = ({ title, author, date, image, excerpt, category, slug }) => {
  return (
    <article className="rounded-lg overflow-hidden bg-neutral-800 min-h-80">
      <img src={image} alt="" className='rounded-t-md '/>
      <div className="p-3 flex flex-col items-between">
      <Link to={`/posts/${slug}`} className="block hover:no-underline">
        <h2 className="text-md text-slate-200 font-bold mb-3 hover:text-teal-400" dangerouslySetInnerHTML={{__html: title}} />
        </Link>
        <div className="flex flex-cols justify-between items-between text-sm">
          <div className="text-slate-400"><p>{date}</p>  </div>
          <p>{category}</p>
        </div>
      </div>
    </article>
  );
};

export default BlogPost;
