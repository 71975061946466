import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import BlogList from './Components/BlogList';
import PostDetail from './Pages/PostDetail';
import HomePage from './Pages/HomePage';
import ContactPage from './Pages/ContactPage';
import FrontendDevelopment from './Pages/FrontEndDevelopment'; 
import Loading from './Components/Loading';  
import BackendDevelopment from './Pages/BackEndDevelopment';
import CustomWebApps from './Pages/CustomWebApps';
import ChatbotDevelopment from './Pages/ChatBotDevelopment';
import Services from './Pages/Services';
import routePaths from './routePaths';
import BlogPostsPage from './Pages/BlogPostsPage';
import { HelmetProvider } from 'react-helmet-async';
import Particles from 'react-particles';
import { loadSlim } from 'tsparticles-slim';
import particlesConfig from './particlesConfig';
import Header from './Components/Header';
import AnimatedCursor from "react-animated-cursor";
import CookiePolicy from './Components/CookiePolicy'; // Importa il componente CookiePolicy
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCookie, faFingerprint, faCommentDots, faTimes } from '@fortawesome/free-solid-svg-icons';
import PrivacyPolicy from './Components/PrivacyPolicy';
import Portfolio from './Pages/Portfolio';
import ProjectDetail from './Pages/Projects/ProjectDetail';
import Chatbot from './Components/Chatbot'; // Assicurati di avere un componente Chatbot
import './Chatbot.css'
import BookingPage from './Pages/BookingPage';
const App = () => {
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showCookiePolicy, setShowCookiePolicy] = useState(false); // Stato per gestire la visualizzazione della Cookie Policy
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false); // Stato per gestire la visualizzazione della Cookie Policy
  const [showChatbot, setShowChatbot] = useState(false); // Stato per gestire la visibilità del Chatbot

  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
  }, []);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_FETCH_POSTS, {
          method: 'GET',
        });

        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }

        const data = await response.json();

        const formattedPosts = data.map((post) => ({
          id: post.id,
          slug: post.slug,
          title: post.title.rendered,
          author: 'Alberto Gaia',
          date: new Date(post.date).toLocaleDateString(),
          image: post._embedded['wp:featuredmedia'] ? post._embedded['wp:featuredmedia'][0].source_url : '',
          excerpt: post.excerpt.rendered,
          content: post.content.rendered,
          categories: post.categories,
        }));
        setPosts(formattedPosts);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_FETCH_CATEGORIES, {
          method: 'GET',
        });
        const data = await response.json();
        const formattedCategories = data.map((category) => ({
          id: category.id,
          name: category.name,
          slug: category.slug,
          parent: category.parent,
        }));
        setCategories(formattedCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    Promise.all([fetchPosts(), fetchCategories()])
      .then(() => setTimeout(() => setLoading(false), 1200))
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <HelmetProvider>
      <AnimatedCursor
        innerSize={7}
        outerSize={30}
        innerScale={1}
        outerScale={2}
        showSystemCursor
        color='45, 212, 191'
        outerAlpha={0.1}
        hasBlendMode={true}
        innerStyle={{
          backgroundColor: 'rgb(45, 212, 191)'
        }}
        outerStyle={{
          border: '3px solid rgb(45, 212, 191)'
        }}
        clickables={[
          { target: 'a', innerSize: 0, outerAlpha: 0.3, backgroundColor: '#000' },
          { target: 'input[type="text"]', innerSize: 0, outerAlpha: 0.3, backgroundColor: '#000' },
          { target: 'input[type="email"]', innerSize: 0, outerAlpha: 0.3, backgroundColor: '#000' },
          { target: 'input[type="number"]', innerSize: 0, outerAlpha: 0.3, backgroundColor: '#000' },
          { target: 'input[type="submit"]', innerSize: 0, outerAlpha: 0.3, backgroundColor: '#000' },
          { target: 'input[type="image"]', innerSize: 0, outerAlpha: 0.3, backgroundColor: '#000' },
          { target: 'label[for]', innerSize: 0, outerAlpha: 0.3, backgroundColor: '#000' },
          { target: 'select', innerSize: 0, outerAlpha: 0.3, backgroundColor: '#000' },
          { target: 'textarea', innerSize: 0, outerAlpha: 0.3, backgroundColor: '#000' },
          { target: 'button', innerSize: 0, outerAlpha: 0.3, backgroundColor: '#000' },
          { target: '.link', innerSize: 0, outerAlpha: 0.3, backgroundColor: '#000' },
        ]}
      />

      <Router>
        <div className={`app-container relative h-dvh`}>
          <div className="content-wrapper bg-none h-dvh max-h-dvh text-white">
            <Header categories={categories} />
            {/* Icona per visualizzare la Cookie Policy */}
            <div className="privacy-icon-container" onClick={() => setShowPrivacyPolicy(true)}>
              <FontAwesomeIcon icon={faFingerprint} className='text-md'/>
            </div>
            
            {/* Modal per la Cookie Policy */}
            {showPrivacyPolicy && (
              <div className="cookie-policy-modal">
                <div className="cookie-policy-content">
                  <button className="close-button text-teal-400" onClick={() => setShowPrivacyPolicy(false)}>X</button>
                  <PrivacyPolicy />
                </div>
              </div>
            )}
            {/* Icona per visualizzare la Cookie Policy */}
            <div className="cookie-icon-container" onClick={() => setShowCookiePolicy(true)}>
              <FontAwesomeIcon icon={faCookie} className='text-md'/>
            </div>
            
            {/* Modal per la Cookie Policy */}
            {showCookiePolicy && (
              <div className="cookie-policy-modal">
                <div className="cookie-policy-content">
                  <button className="close-button text-teal-400" onClick={() => setShowCookiePolicy(false)}>X</button>
                  <CookiePolicy />
                </div>
              </div>
            )}

            <div className="fixed bottom-0 left-1 text-xs"><p className='text-gray-100'>P.IVA: 13091770019</p></div>
            <AnimatedRoutes posts={posts} categories={categories}/>
          </div>

          {/* Pulsante per aprire/chiudere la chat */}
          <button 
            className={"fixed bottom-5 right-5 bg-teal-600 text-white rounded-full shadow-lg z-[100] w-14 h-14 flex items-center justify-center hover:bg-teal-300 " + (!showChatbot ? "animate-bounce" : '')}
            onClick={() => setShowChatbot(!showChatbot)}
          >
            {showChatbot ? <FontAwesomeIcon icon={faTimes} className='text-2xl' /> : <FontAwesomeIcon icon={faCommentDots} className='text-2xl' />}
          </button>

          {/* Chatbot */}
          {showChatbot && (
            <div id="chatbot-container" className="fixed inset-0 sm:bottom-20 sm:right-5 sm:inset-auto  h-screen sm:max-h-[500px]  bg-white shadow-lg shadow-teal-500/40 rounded-lg overflow-hidden z-[100] xs:w-screen">
              <Chatbot onClose={() => setShowChatbot(false)} />
            </div>
          )}

          <Particles id="tsparticles" options={particlesConfig} init={particlesInit} loaded={particlesLoaded} />
        </div>
      </Router>
    </HelmetProvider>
  );
};

const AnimatedRoutes = ({ posts, categories }) => {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path={routePaths.home} element={<PageWrapper><HomePage posts={posts} categories={categories} /></PageWrapper>} />
        <Route path={routePaths.post} element={<PageWrapper><PostDetail posts={posts} /></PageWrapper>} />
        <Route path={routePaths.category} element={<PageWrapper><BlogList posts={posts} categories={categories} /></PageWrapper>} />
        <Route path={routePaths.contact} element={<PageWrapper><ContactPage /></PageWrapper>} />
        <Route path={routePaths.frontendDevelopment} element={<PageWrapper><FrontendDevelopment /></PageWrapper>} />
        <Route path={routePaths.backendDevelopment} element={<PageWrapper><BackendDevelopment /></PageWrapper>} />
        <Route path={routePaths.customWebApps} element={<PageWrapper><CustomWebApps /></PageWrapper>} />
        <Route path={routePaths.chatbotDevelopment} element={<PageWrapper><ChatbotDevelopment /></PageWrapper>} />
        <Route path={routePaths.services} element={<PageWrapper><Services /></PageWrapper>} />
        <Route path={routePaths.portfolio} element={<PageWrapper><Portfolio /></PageWrapper>} />
        <Route path={routePaths.project} element={<PageWrapper><ProjectDetail /></PageWrapper>} />
        <Route path={routePaths.posts} element={<PageWrapper><BlogPostsPage posts={posts} categories={categories} /></PageWrapper>} />
        <Route path="/category" element={<PageWrapper><BlogPostsPage posts={posts} categories={categories} /></PageWrapper>} />
        <Route path={routePaths.booking} element={<PageWrapper><BookingPage /></PageWrapper>} />
         {/* Route per gestire le pagine non trovate */}
         <Route path="*" element={<PageWrapper><HomePage posts={posts} categories={categories} /></PageWrapper>} />
      </Routes>
    </AnimatePresence>
  );
};

const PageWrapper = ({ children }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 1 }}
    className='min-h-[calc(100vh-80px)] pb-10 max-w-4xl mx-auto flex items-center justify-center'
  >
    {children}
  </motion.div>
);

export default App;
