import React from 'react';
import BlogPost from '../Components/BlogPost';
import { Helmet } from 'react-helmet-async';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const BlogPostsPage = ({ posts, categories }) => {
  const blogCategory = categories.find(category => category.slug === 'blog');

  if (!blogCategory) {
    return <div>Loading...</div>;
  }

  const blogPosts = posts.filter(post => post.categories.includes(blogCategory.id));

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  };

  return (
    <section className="space-y-8 max-w-3xl overflow-hidden mx-auto p-8 dark:text-white max-h-screen flex-1 sm:p-6 sm:p-8 lg:p-10">

      <Helmet>
        <title>Blog | Alberto Gaia</title>
        <meta name="description" content="Leggi gli ultimi articoli del blog" />
      </Helmet>
      <h2 className="text-2xl font-bold text-white uppercase inline-block border-b-4 border-teal-400 font-mono ">
        Posts dal Blog
      </h2>
      <Slider {...settings}>
        {blogPosts.map(post => (
          <div key={post.id} className="p-4">
              <BlogPost
                title={post.title}
                author={post.author}
                date={post.date}
                image={post.image}
                excerpt={post.excerpt}
                slug={post.slug}
              />
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default BlogPostsPage;
