import React from 'react';
import { Link } from 'react-router-dom';


const ContactMe = () =>{
    return(
      <section id="contact-banner" className="mx-auto bg-teal-800 text-white p-6 rounded-lg shadow-md text-center">
        <h2 className="ps-3 text-2xl font-bold mb-4 font-mono">Contattami</h2>
        <p className="mb-4">Vuoi saperne di più o iniziare un progetto? Contattami oggi stesso!</p>
        <Link to="/contattami" className="bg-teal-900 transition hover:bg-teal-400 hover:text-white text-white px-4 py-2 rounded-lg shadow-md font-semibold">Contattami</Link>

      </section>
    )
}

export default ContactMe;