import React from 'react';
import { Accordion } from 'rsuite';

import 'rsuite/dist/rsuite-no-reset.min.css';

import '../CookiePolicy.css';

const CookiePolicy = () => {
  return (
    <div className="cookie-policy text-md">
      <h1>Cookie Policy di GAIA ALBERTO</h1>
      <p>
        Questo documento contiene informazioni in merito alle tecnologie che consentono a GAIA ALBERTO di raggiungere gli scopi descritti di seguito. Tali tecnologie permettono al Titolare di raccogliere e salvare informazioni (per esempio tramite l’utilizzo di Cookie) o di utilizzare risorse (per esempio eseguendo uno script) sul dispositivo dell’Utente quando quest’ultimo interagisce con GAIA ALBERTO.
      </p>
      <p>
        Per semplicità, in questo documento tali tecnologie sono sinteticamente definite “Strumenti di Tracciamento”, salvo vi sia ragione di differenziare.
      </p>
      
      <Accordion bordered>
        <Accordion.Panel header="Attività strettamente necessarie a garantire il funzionamento di GAIA ALBERTO" defaultExpanded>
          <p>
            GAIA ALBERTO utilizza Cookie comunemente detti “tecnici” o altri Strumenti di Tracciamento analoghi per svolgere attività strettamente necessarie a garantire il funzionamento o la fornitura del Servizio.
          </p>
        </Accordion.Panel>

        <Accordion.Panel header="Strumenti di Tracciamento di terza parte">
          <h3>Gestione dei tag</h3>
          <p>
            Questo tipo di servizi è funzionale alla gestione centralizzata dei tag o script utilizzati su GAIA ALBERTO. L'uso di tali servizi comporta il fluire dei Dati dell'Utente attraverso gli stessi e, se del caso, la loro ritenzione.
          </p>
          <p>
            <strong>Google Tag Manager (Google Ireland Limited)</strong><br />
            Google Tag Manager è un servizio di gestione dei tag fornito da Google Ireland Limited.<br />
            Dati Personali trattati: Strumenti di Tracciamento.<br />
            Luogo del trattamento: Irlanda – <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
          </p>
        </Accordion.Panel>

        <Accordion.Panel header="Altre attività che prevedono l’utilizzo di Strumenti di Tracciamento">
          <h3>Misurazione</h3>
          <p>
            GAIA ALBERTO utilizza Strumenti di Tracciamento per misurare il traffico e analizzare il comportamento degli Utenti per migliorare il Servizio.
          </p>
          <p>
            <strong>Google Analytics (Universal Analytics) con IP anonimizzato (Google Ireland Limited)</strong><br />
            Google Analytics (Universal Analytics) è un servizio di analisi web fornito da Google Ireland Limited (“Google”). Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di GAIA ALBERTO, compilare report e condividerli con gli altri servizi sviluppati da Google. Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario. Questa integrazione di Google Analytics rende anonimo il tuo indirizzo IP.<br />
            Dati Personali trattati: Dati di utilizzo e Strumenti di Tracciamento.<br />
            Luogo del trattamento: Irlanda – <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> – <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">Opt Out</a>.
          </p>
          <p>
            <strong>Durata di archiviazione:</strong><br />
            AMP_TOKEN: 1 ora<br />
            _ga: 2 anni<br />
            _gac*: 3 mesi<br />
            _gat: 1 minuto<br />
            _gid: 1 giorno
          </p>
        </Accordion.Panel>

        <Accordion.Panel header="Come gestire le preferenze e prestare o revocare il consenso">
          <p>
            Esistono vari modi per gestire le preferenze relative agli Strumenti di Tracciamento e per prestare o revocare il consenso, ove necessario.
          </p>
          <p>
            Gli Utenti possono gestire le preferenze relative agli Strumenti di Tracciamento direttamente tramite le impostazioni dei propri dispositivi - per esempio, possono impedire l’uso o l’archiviazione di Strumenti di Tracciamento.
          </p>
          <p>
            In aggiunta, ogni qualvolta l’utilizzo di Strumenti di Tracciamento dipenda da consenso, l’Utente può prestare o revocare tale consenso impostando le proprie preferenze all’interno dell’informativa sui cookie o aggiornando tali preferenze tramite il widget privacy per le preferenze relative al consenso, se presente.
          </p>
          <p>
            Grazie ad apposite funzioni del browser o del dispositivo è anche possibile rimuovere Strumenti di Tracciamento precedentemente salvati, inclusi quelli utilizzati per il salvataggio delle preferenze relative al consenso inizialmente espresse dall'Utente.
          </p>
          <p>
            Altri Strumenti di Tracciamento presenti nella memoria locale del browser possono essere rimossi cancellando la cronologia di navigazione.
          </p>
          <p>
            Per quanto riguarda Strumenti di Tracciamento di terza parte, gli Utenti possono gestire le proprie preferenze visitando il relativo link di opt out (qualora disponibile), utilizzando gli strumenti descritti nella privacy policy della terza parte o contattando quest'ultima direttamente.
          </p>
        </Accordion.Panel>

        <Accordion.Panel header="Individuare le impostazioni relative agli Strumenti di Tracciamento">
          <p>
            Gli Utenti possono, per esempio, trovare informazioni su come gestire i Cookie in alcuni dei browser più diffusi ai seguenti indirizzi:
          </p>
          <ul>
            <li><a href="https://support.google.com/chrome/answer/95647?hl=it" target="_blank" rel="noopener noreferrer">Google Chrome</a></li>
            <li><a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie" target="_blank" rel="noopener noreferrer">Mozilla Firefox</a></li>
            <li><a href="https://support.apple.com/it-it/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">Apple Safari</a></li>
            <li><a href="https://support.microsoft.com/it-it/help/4027947/windows-delete-cookies" target="_blank" rel="noopener noreferrer">Microsoft Edge</a></li>
            <li><a href="https://support.brave.com/hc/en-us/articles/360021814972-How-do-I-manage-cookies-" target="_blank" rel="noopener noreferrer">Brave</a></li>
            <li><a href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank" rel="noopener noreferrer">Opera</a></li>
          </ul>
          <p>
            Gli Utenti possono inoltre gestire alcuni Strumenti di Tracciamento per applicazioni mobili disattivandoli tramite le apposite impostazioni del dispositivo, quali le impostazioni di pubblicità per dispositivi mobili o le impostazioni relative al tracciamento in generale (gli Utenti possono consultare le impostazioni del dispositivo per individuare quella pertinente).
          </p>
          <p>
            Fermo restando quanto precede, si informano gli Utenti della possibilità di avvalersi delle informazioni presenti su <a href="https://www.youronlinechoices.com/it/" target="_blank" rel="noopener noreferrer">YourOnlineChoices</a> (UE e Regno Unito), <a href="https://www.networkadvertising.org/" target="_blank" rel="noopener noreferrer">Network Advertising Initiative</a> (USA) e <a href="https://digitaladvertisingalliance.org/" target="_blank" rel="noopener noreferrer">Digital Advertising Alliance</a> (USA), <a href="https://youradchoices.ca/" target="_blank" rel="noopener noreferrer">DAAC</a> (Canada), <a href="https://www.ddai.info/" target="_blank" rel="noopener noreferrer">DDAI</a> (Giappone) o altri servizi analoghi. Con questi servizi è possibile gestire le preferenze di tracciamento della maggior parte degli strumenti pubblicitari. Il Titolare, pertanto, consiglia agli Utenti di utilizzare tali risorse in aggiunta alle informazioni fornite nel presente documento.
          </p>
        </Accordion.Panel>

        <Accordion.Panel header="Conseguenze legate al rifiuto dell'utilizzo di Strumenti di Tracciamento">
          <p>
            Gli Utenti sono liberi di decidere se permettere o meno l'utilizzo di Strumenti di Tracciamento. Tuttavia, si noti che gli Strumenti di Tracciamento consentono a GAIA ALBERTO di fornire agli Utenti un'esperienza migliore e funzionalità avanzate (in linea con le finalità delineate nel presente documento). Pertanto, qualora l'Utente decida di bloccare l'utilizzo di Strumenti di Tracciamento, il Titolare potrebbe non essere in grado di fornire le relative funzionalità.
          </p>
        </Accordion.Panel>
      </Accordion>
      
      <h2>Titolare del Trattamento dei Dati</h2>
      <p>
        <strong>GAIA ALBERTO</strong><br />
        Email: <a href="mailto:albertogaia.dev@gmail.com">albertogaia.dev@gmail.com</a>
      </p>
      <p>
        Dal momento che l’uso di Strumenti di Tracciamento di terza parte su GAIA ALBERTO non può essere completamente controllato dal Titolare, ogni riferimento specifico a Strumenti di Tracciamento di terza parte è da considerarsi indicativo. Per ottenere informazioni complete, gli Utenti sono gentilmente invitati a consultare la privacy policy dei rispettivi servizi terzi elencati in questo documento.
      </p>
      <p>
        Data l'oggettiva complessità di identificazione delle tecnologie di tracciamento, gli Utenti sono invitati a contattare il Titolare qualora volessero ricevere ulteriori informazioni in merito all'utilizzo di tali tecnologie su GAIA ALBERTO.
      </p>
      <h2>Definizioni e riferimenti legali</h2>
      <Accordion bordered>
        <Accordion.Panel header="Dati Personali (o Dati)">
          <p>
            Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o identificabile una persona fisica.
          </p>
        </Accordion.Panel>

        <Accordion.Panel header="Dati di Utilizzo">
          <p>
            Sono le informazioni raccolte automaticamente attraverso GAIA ALBERTO (anche da applicazioni di parti terze integrate in GAIA ALBERTO), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall’Utente che si connette con GAIA ALBERTO, gli indirizzi in notazione URI (Uniform Resource Identifier), l’orario della richiesta, il metodo utilizzato nell’inoltrare la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal visitatore, le varie connotazioni temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all’itinerario seguito all’interno dell’Applicazione, con particolare riferimento alla sequenza delle pagine consultate, ai parametri relativi al sistema operativo e all’ambiente informatico dell’Utente.
          </p>
        </Accordion.Panel>

        <Accordion.Panel header="Utente">
          <p>
            L'individuo che utilizza GAIA ALBERTO che, salvo ove diversamente specificato, coincide con l'Interessato.
          </p>
        </Accordion.Panel>

        <Accordion.Panel header="Interessato">
          <p>
            La persona fisica cui si riferiscono i Dati Personali.
          </p>
        </Accordion.Panel>

        <Accordion.Panel header="Responsabile del Trattamento (o Responsabile)">
          <p>
            La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per conto del Titolare, secondo quanto esposto nella presente privacy policy.
          </p>
        </Accordion.Panel>

        <Accordion.Panel header="Titolare del Trattamento (o Titolare)">
          <p>
            La persona fisica o giuridica, l'autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di GAIA ALBERTO. Il Titolare del Trattamento, salvo quanto diversamente specificato, è il titolare di GAIA ALBERTO.
          </p>
        </Accordion.Panel>

        <Accordion.Panel header="GAIA ALBERTO (o questa Applicazione)">
          <p>
            Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti.
          </p>
        </Accordion.Panel>

        <Accordion.Panel header="Servizio">
          <p>
            Il Servizio fornito da GAIA ALBERTO così come definito nei relativi termini (se presenti) su questo sito/applicazione.
          </p>
        </Accordion.Panel>

        <Accordion.Panel header="Unione Europea (o UE)">
          <p>
            Salvo ove diversamente specificato, ogni riferimento all’Unione Europea contenuto in questo documento si intende esteso a tutti gli attuali stati membri dell’Unione Europea e dello Spazio Economico Europeo.
          </p>
        </Accordion.Panel>

        <Accordion.Panel header="Cookie">
          <p>
            I Cookie sono Strumenti di Tracciamento che consistono in piccole porzioni di dati conservate all'interno del browser dell'Utente.
          </p>
        </Accordion.Panel>

        <Accordion.Panel header="Strumento di Tracciamento">
          <p>
            Per Strumento di Tracciamento s’intende qualsiasi tecnologia - es. Cookie, identificativi univoci, web beacons, script integrati, e-tag e fingerprinting - che consenta di tracciare gli Utenti, per esempio raccogliendo o salvando informazioni sul dispositivo dell’Utente.
          </p>
        </Accordion.Panel>
      </Accordion>

      <p>
        Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente GAIA ALBERTO.
      </p>
      <p>
        <strong>Ultima modifica:</strong> 28 Giugno 2024
      </p>
    </div>
  );
};

export default CookiePolicy;
