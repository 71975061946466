import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faTimes, faTrashCan } from '@fortawesome/free-solid-svg-icons';

const Chatbot = ({ onClose }) => {
  const [messages, setMessages] = useState(() => {
    const savedMessages = getLocalStorageWithExpiry('chatMessages');
    return savedMessages || [];
  });
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(() => {
    const savedUserDetails = getLocalStorageWithExpiry('userDetails');
    return savedUserDetails || { name: '', email: '' };
  });
  const [isUserDetailsCollected, setIsUserDetailsCollected] = useState(() => {
    const savedUserDetails = getLocalStorageWithExpiry('userDetails');
    return savedUserDetails ? savedUserDetails.email !== '' : false;
  });

  useEffect(() => {
    setLocalStorageWithExpiry('chatMessages', messages, 24 * 60 * 60 * 1000); // 1 day in milliseconds
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    setLocalStorageWithExpiry('userDetails', userDetails, 24 * 60 * 60 * 1000); // 1 day in milliseconds
  }, [userDetails]);

  const scrollToBottom = () => {
    const chatMessagesContainer = document.getElementById('chat-messages');
    chatMessagesContainer.scrollTop = chatMessagesContainer.scrollHeight;
  };

  const handleSend = async () => {
    if (input.trim() === '') return;

    if (!isUserDetailsCollected) {
      if (!userDetails.name) {
        setUserDetails({ ...userDetails, name: input });
        setMessages([...messages, { sender: 'user', text: input, timestamp: new Date().toISOString() }]);
        setInput('');
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'bot', text: 'Grazie! Ora per favore forniscimi il tuo indirizzo email', timestamp: new Date().toISOString() }
        ]);
      } else if (!userDetails.email) {
        setUserDetails({ ...userDetails, email: input });
        setMessages([...messages, { sender: 'user', text: input, timestamp: new Date().toISOString() }]);
        setInput('');
        setIsUserDetailsCollected(true);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'bot', text: 'Grazie! Come posso aiutarti?', timestamp: new Date().toISOString() }
        ]);
      }
      return;
    }

    const userMessage = { sender: 'user', text: input, timestamp: new Date().toISOString() };
    setMessages([...messages, userMessage]);
    setInput('');

    try {
      setLoading(true);

      const response = await axios.post('https://albertogaia.xyz/ag-dashboard/public/api/chat', {
        messages: [...messages, userMessage],
        user: userDetails
      });

      const botMessage = {
        sender: 'bot',
        text: response.data.choices[0].message.content,
        timestamp: new Date().toISOString(),
      };

      setMessages([...messages, userMessage, botMessage]);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching response:', error);
      setLoading(false);
    }
  };

  const handleClearMessages = () => {
    setMessages([]);
    setUserDetails({ name: '', email: '' });
    localStorage.removeItem('chatMessages');
    localStorage.removeItem('userDetails');
    setIsUserDetailsCollected(false);
  };

  const renderMessageContent = (message) => {
    const formatContent = (content) => {
      return content
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
        .replace(/\n/g, '<br>');
    };

    if (message.sender === 'bot') {
      const htmlContent = formatContent(message.text);
      return <span dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    } else {
      return <span>{message.text}</span>;
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-dvh sm:max-h-[500px] text-gray-800 z-[1000]">
      <div className="p-2 bg-teal-400 flex justify-between items-center w-full" style={{ height: '60px' }}>
        <p className='inline-block'>AlbertoBot</p>
        <div>
          <FontAwesomeIcon icon={faTrashCan} className='text-md cursor-pointer hover:text-slate-300 mr-4' onClick={handleClearMessages} />
          <FontAwesomeIcon icon={faTimes} className='text-2xl cursor-pointer hover:text-slate-300' onClick={onClose} />
        </div>
      </div>
      <div className="flex flex-col flex-grow w-full bg-slate-200 shadow-xl rounded-lg overflow-hidden">
        <div className="flex flex-col flex-grow h-0 p-4 overflow-auto" id='chat-messages'>
          {!isUserDetailsCollected && (
            <>
              <div className="flex w-full mt-2 space-x-3 max-w-xs">
                <div className="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300">
                  <img src="../images/1636989275052.jpeg" alt="" className="rounded-full" />
                </div>
                <div>
                  <div className="p-3 rounded-lg bg-gray-300 text-black rounded-r-lg rounded-bl-lg">
                    <p className="text-sm">Ciao, prima di rispondere a tutte le tue domande ti chiedo cortesemente di fornirmi alcune informazioni per poterti poi contattare solo in caso di necessità (niente email spammose ecc...) e per poter migliorare le risposte di questo chatbot! <br />
                    Per favore forniscimi il tuo nome.</p>
                  </div>
                </div>
              </div>
            </>
          )}
          {messages.map((message, index) => (
            <div
              key={index}
              className={`flex w-full mt-2 space-x-3 max-w-xs ${message.sender === 'user' ? 'ml-auto justify-end' : ''}`}
            >
              {message.sender === 'bot' && (
                <div className="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300">
                  <img src="../images/1636989275052.jpeg" alt="" className="rounded-full" />
                </div>
              )}
              <div>
                <div
                  className={`p-3 rounded-lg ${message.sender === 'user' ? 'bg-blue-600 text-white rounded-l-lg rounded-br-lg' : 'bg-gray-300 text-black rounded-r-lg rounded-bl-lg'}`}
                >
                  <p className="text-sm">{renderMessageContent(message)}</p>
                </div>
                <span className="text-xs text-gray-500 leading-none">
                  {formatTimestamp(message.timestamp)}
                </span>
              </div>
              {message.sender === 'user' && (
                <div className="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300">
                  <img src="../images/user.jpg" alt="" className="rounded-full" />
                </div>
              )}
            </div>
          ))}
          {loading && (
            <div className="flex w-full mt-2 space-x-3 max-w-xs">
              <div>
                <span className="text-xs text-gray-500 leading-none">
                  Sta scrivendo...
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="bg-gray-300 p-4 flex items-center">
          <input
            className="flex-grow h-10 rounded px-3 text-sm"
            type="text"
            placeholder="Scrivi il tuo messaggio..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => (e.key === 'Enter' ? handleSend() : null)}
          />
          <button
            onClick={handleSend}
            className="ml-2 px-3 py-2 rounded-lg bg-teal-500 text-white hover:bg-teal-400"
          >
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        </div>
        <p className='text-[10px] text-slate-200 py-1 bg-slate-500 text-center'>Created by Alberto Gaia</p>
      </div>
    </div>
  );
};

// Funzioni helper per gestire l'expiry del local storage
function setLocalStorageWithExpiry(key, value, ttl) {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function getLocalStorageWithExpiry(key) {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

export default Chatbot;
